/**
 * Created by tsmith39 on 1/27/2016.
 */
$(document).ready(function () {

    $('select').select2();
    $('.members-ajax-text-with-tagging').select2({
        tags: true,
        ajax: {
            url: '/member/search',
            dataType: 'json',
            delay: 400,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page
                };
            },
            processResults: function (data, page) {
                return {
                    results: data
                };
            }
        }
    });
    $('.members-ajax-text').select2({
        ajax: {
            url: '/member/search',
            dataType: 'json',
            delay: 400,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page
                };
            },
            processResults: function (data, page) {
                return {
                    results: data
                };
            }
        }
    });
    $('.GroupInput').select2({
        ajax: {
            url: '/group/search',
            dataType: 'json',
            delay: 400,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page
                };
            },
            processResults: function (data, page) {
                return {
                    results: data
                };
            }
        }
    });

    $('.dataTableGeneral').dataTable({
        "order": [[1, "desc"]]
    });
    $('.dataTableClean2').dataTable({
        "order": [[0, "desc"]]
    });
    $('.dataTableMembers').dataTable({
        responsive: true,
        "order": [[3, "desc"]],
        "processing": true,
        serverSide: true,
        ajax: {
            url: '/member/ajax/',
            dataFilter: function (data) {

                return data;
            }
        }
    });
    $('.dataTableOptions').dataTable({
        responsive: true,
        "order": [[0, "asc"]],
        "processing": true,
        serverSide: true,
        ajax: {
            url: '/option/ajax/',
            dataFilter: function (data) {
                return data;
            }
        }
    });
    $('.dataTableUsers').dataTable({
        responsive: true,
        "order": [[0, "desc"]],
        "processing": true,
        serverSide: true,
        columnDefs: [{
            "targets": 2,
            "orderable": false
        }],
        ajax: {
            url: '/user/ajax/',
            dataFilter: function (data) {
                // var json = jQuery.parseJSON(data);
                // json.recordsTotal = json.total;
                // json.recordsFiltered = json.total;
                // json.data = json.list;

                // return JSON.stringify(json); // return JSON string
                return data;
            }
        }
    });
    $('.dataTableMeeting').dataTable({
        responsive: true,
        "order": [[1, "desc"]],
        "processing": true,
        serverSide: true,
        ajax: {
            url: '/meeting/ajaxMeetingTable/',
            dataFilter: function (data) {
                // var json = jQuery.parseJSON(data);
                // json.recordsTotal = json.total;
                // json.recordsFiltered = json.total;
                // json.data = json.list;

                // return JSON.stringify(json); // return JSON string
                return data;
            },
            "data": function (d) {
                d.group_id = $('#group-id').text();
            }
        }
    });
    $('input[name="SingleDatePicker"]').daterangepicker({
        locale: {
            format: 'YYYY-MM-DD'
        },
        singleDatePicker: true
    });

});